.notes {
  margin-bottom: var(--length4);
}

.list {
  list-style-type: "*";
  padding-left: var(--length2);
  margin: 0;
}

.item {
  padding-left: var(--length1);
}

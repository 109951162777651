.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: var(--length1);

  & > span {
    display: flex;
  }
}

.item {
  position: relative;
  display: flex;
}

.link {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.selected {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  font-weight: var(--fw-light-bold);
  color: var(--font-color2);
  border: 1px solid var(--border-color-main);
  text-transform: uppercase;
  background-color: var(--color-gray5);
  transition: 0.1s background-color ease-in-out;

  &:hover {
    background-color: var(--color-gray3);
  }
}
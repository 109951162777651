$desktop: 1024px;

.logo {
  width: var(--header-height);
  background: var(--color-blue1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: var(--length3);
  flex-shrink: 0;
}

.logoLink {
  display: flex;
}

@media screen and (min-width: $desktop) {
  .logo {
    display: none;
  }
}
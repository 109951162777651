.root {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  h1 {
    font-size: 30px;
    text-align: center;
    color: #1F3366;
  }

  h2 {
    font-size: 18px;
    text-align: center;
  }
}

.paper {
  background: var(--color-gray6);
  border: 1px solid #E7E7E7;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.14);
  border-radius: 7px;
}

.stepper {
  background-color: var(--color-gray4-4);
  padding: 24px 16px;
}

.body {
  display: flex;
  padding: 24px 16px 48px;
  justify-content: center;
  @media screen and (min-width: 1024px) {
    padding: 48px 24px 96px;
  }
}

.form {
  max-width: 100%;
  gap: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 1024px) {
    min-width: 450px;
    max-width: 580px;
    min-height: 300px;
  }
  & > p {
    text-align: center;
    margin: 0;
  }
}

.actions {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
}

.colorSuccess {
  color: var(--alert-success-color);
}

.colorError {
  color: var(--color-red1);
}

.colorWarning {
  color: var(--color-orange1);
}

.explanation {
  flex: 1;
  align-items: flex-end;
  display: flex;
}

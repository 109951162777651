$asideWidth: 260px;
$desktop: 1024px;

.icon {
  line-height: 28px;
}

.btn {
  width: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  margin-left: var(--length1);
}

.aside {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: $asideWidth;
  z-index: var(--z-index-sidenav);
  background: var(--color-gray1);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  transform: translateX($asideWidth);
  transition: transform linear var(--animation-fast);
}

.open {
  transform: translateX(0px);
}

.header {
  display: flex;
  justify-content: flex-end;
  height: var(--header-height);
  background: var(--color-blue1);

  & .icon {
    color: var(--color-gray6);
  }
}

@media screen and (min-width: $desktop) {
  .btn,
  .aside {
    display: none;
  }
}
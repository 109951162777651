.root {
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu {
  background: var(--color-gray1);
  margin: var(--length6) 0;
}

.item {
  margin: 0 8px;
  border-radius: var(--border-radius-strong);
  color: var(--color-gray6);
  text-decoration: none;
  display: flex;
  height: var(--length8);
  padding: 0 var(--length2);
  align-items: center;

  & + .item {
    margin-top: var(--length1);
  }

  &.secondary {
    background-color: var(--color-gray1-2);
  }

  &.active {
    background-color: var(--color-blue4);
  }
}

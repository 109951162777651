@import '~@tt2/components/build/variables.css';
@import '~@tt2/components/build/icons.css';
:root {
  --header-height: var(--length12);
}

html,
body,
#root {
  min-height: 100%;
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
  background: #fff;
}

h1.page-title {
  margin-top: 0;
  font-weight: var(--fw-light-bold);
}

a {
  color: inherit;
}

// .header {
//   background: #2D5799;
//   padding: 18px 48px;
// }

// .logo {
//   display: block;
//   height: 36px;
// }

// .logo>img {
//   display: block;
//   height: 100%;
// }

// .container {
//   max-width: 600px;
//   margin: 100px auto 0;
//   text-align: center;
// }

// .heading {
//   font-size: 24px;
//   line-height: 32px;
//   margin: 0;
// }

// .heading-small {
//   font-size: 14px;
//   line-height: 28px;
// }

// .response-icon {
//   display: block;
//   height: 50px;
//   margin: 0 auto 8px;
// }

// .paragraph, .heading>.small-print {
//   display: block;
//   font-size: 14px;
//   line-height: 28px;
//   font-weight: 400;
//   margin: 0;
// }

// .help-links, .register-form, .results-form {
//   max-width: 340px;
//   margin: 0 auto;
// }

// .register-form, .results-form {
//   text-align: left;
//   margin: 24px auto;
// }

// .field {
//   margin-bottom: 18px;
// }

// .field-label {
//   font-size: 13px;
//   line-height: 18px;
//   color: #999;
//   transition: all .3s;
// }

// .field-input {
//   font-size: 13px;
//   line-height: 18px;
//   color: #333;
//   width: 100%;
//   transition: all .3s;
//   border: solid 1px #E4E4E4;
//   border-radius: 5px;
//   margin: 0 -5px;
//   padding: 8px 5px;
//   background: #fff;
//   -webkit-box-shadow: none;
//   -moz-box-shadow: none;
//   box-shadow: none;
//   outline: none;
// }

// .field-input:focus {
//   border: solid 1px #3F7DC0;
//   box-shadow: 0 0 0 1px #3F7DC0;
//   border-radius: 5px;
// }

// .field-input-error {
//   border: solid 1px #D84F4F;
// }

// .field-label-focus {
//   color: #3F7DC0;
// }

// .field-error {
//   color: #D84F4F;
//   font-size: 13px;
// }

// .submit-button {
//   font-family: inherit;
//   overflow: visible;
//   -webkit-appearance: button;

//   cursor: pointer;
//   text-transform: uppercase;
//   font-size: 13px;
//   font-weight: bold;
//   line-height: 18px;
//   box-sizing: content-box;
//   color: #fff;
//   width: 100%;
//   transition: all .3s;
//   background: #3F7DC0;
//   border: solid 1px #3F7DC0;
//   border-radius: 5px;
//   margin: 0 -5px;
//   padding: 8px 5px;
//   outline: none;
// }

// .submit-button:focus {
//   border: solid 1px #2D5799;
//   box-shadow: 0 0 0 1px #2D5799;
// }

// .download-link:hover, .copy-button:hover, .submit-button:hover {
//   opacity: 0.75;
// }

// .download-link, .copy-button {
//   display: block;
//   cursor: pointer;
//   text-decoration: none;
//   text-transform: uppercase;
//   font-size: 13px;
//   font-weight: bold;
//   line-height: 18px;
//   box-sizing: content-box;
//   color: #3F7DC0;
//   width: 100%;
//   transition: all .3s;
//   background: #fff;
//   border: solid 1px #3F7DC0;
//   border-radius: 5px;
//   margin: 0 -5px;
//   padding: 8px 5px;
// }

// .copy-wrapper {
//   position: relative;
//   display: block;
// }

// .copy-button {
//   position: absolute;
//   width: auto;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   margin-right: -7px;
//   border: solid 1px #E4E4E4;
//   padding: 8px 30px;
// }

// .copy-button-focus {
//   border: solid 1px #3F7DC0;
//   box-shadow: 0 0 0 1px #3F7DC0;
// }

// .reference-list {
//   list-style: none;
//   padding: 0;
//   margin: 8px 0 0;
// }

// .reference-list>li {
//   margin-bottom: 8px;
// }

// .submit-disabled {
//   background: #ccc;
//   border: solid 1px #999;
// }

// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// input[type=number] {
//   -moz-appearance: textfield;
// }

.root {
  display: flex;
  flex-direction: row;
  gap: 16px
}

.step {
  flex: 1;
  position: relative;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 12px;
}

.circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F4F7FF;
  font-size: var(--font-size7);
  color: var(--color-gray2);
  font-weight: 500;
  border-color: #E6E6E6;
  border-style: solid;
  border-width: 2px;
}

.label {
  color: var(--color-gray2);
  font-size: var(--font-size6);
  font-weight: 700;
  text-align: center;
}

.active {
  .label {
    color: var(--color-gray1);
  }

  .circle {
    border-color: var(--color-blue4);
    color: var(--color-blue4);
  }
}

.completed {
  .label {
    color: var(--color-gray1);
  }

  .circle {
    background-color: var(--color-blue4);
    border-color: var(--color-blue4);
    color: var(--color-gray6);
  }
}

.connector {
  flex: 1 1 auto;
  position: absolute;
  top: 30px;
  left: calc(-50% + 24px);
  right: calc(50% + 40px);

  > span {
    display: block;
    border-color: #E7E7E7;
    border-top-style: solid;
    border-top-width: 2px;
  }
}

.completed, .active {
  .connector {
    > span {
      border-color: var(--color-blue4);
    }
  }
}

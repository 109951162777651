.list {
  border: 1px solid var(--color-gray3);
  padding: 20px 20px 20px 40px;
  border-radius: 5px;
  max-width: 400px;
}

.link {
  color: var(--color-blue1);
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.input {
  margin: 0 !important;

  & > div {
    display: flex;
  }

  & label {
    flex: 1 1 auto;
    min-width: 0;

    & > div > span {
      display: block;
      white-space: break-spaces !important;
    }
  }
}

$desktop: 1024px;
$xdesktop: 1440px;

@media screen and (min-width: $desktop) {
  .responsiveMd {
    width: 50%;
  }

  .responsiveLg {
    width: 75%;
  }
}

@media screen and (min-width: $xdesktop) {
  .responsiveMd {
    width: 30%;
  }

  .responsiveLg {
    width: 50%;
  }
}
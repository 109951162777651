.termsAndConditions {

  max-width: 750px;
  margin: 0 auto;

  /* Table Of Contents block */

  & > div:first-of-type {
    margin-bottom: 20px;

    span {
      font-weight: 600;
      font-size: 20px;
    }

    li {
      list-style-type: decimal;
    }

    a {
      display: block;
      text-transform: unset;
      text-decoration: none;
    }
  }

  /* Sections block */

  & > ul {

    padding: 0;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    div {
      counter-increment: pCount;
      justify-content: center;
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      margin-bottom: 30px;

      > li {
        text-align: justify;
        list-style-type: decimal;

        a {
          word-break: break-word;
          text-decoration: none
        }

        &::marker {
          display: list-item;
          content: "§" counter(pCount) " ";
          margin-right: 5px;
        }

        margin: 0 50px 10px;
        font-weight: 600;
        font-size: 20px;
      }

      li > ul {
        padding-left: 40px;

        li {
          list-style-type: lower-latin;
        }
      }

      > ul {
        list-style-type: decimal;
      }

      ul a {
        word-break: break-word;
      }

      &:last-of-type {
        margin-bottom: 60px;
      }

      & ul {
        padding-left: 20px;
      }
    }

    .provisions_applicable_to_business_customers {

      li > ul {
        padding-left: 25px;
      }

      li > ul li {
        counter-increment: chapter;
        list-style-type: none;

        &:before {
          content: counter(chapter, lower-alpha) ")" " ";
        }

      }
    }
  }
}

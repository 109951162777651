.content {
  & strong {
    white-space: nowrap;
  }

  & section {
    list-style-type: decimal;
    margin-top: 0;
    margin-bottom: 0;

    & section {
      list-style-type: lower-alpha;
      margin-left: var(--length5);
      margin-bottom: var(--length3);
    }
  }
}

.listItem {
  display: list-item;
  margin-left: var(--length5);
  margin-bottom: var(--length1);
}

.para {
  display: block;
  margin-bottom: var(--length1);
}
$desktop: 1024px;
$asideWidth: 206px;

.layout {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
}

.main {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  flex-direction: column;
}

.aside {
  display: none;
  flex-direction: column;
  flex-shrink: 0;
  width: $asideWidth;
  background: var(--color-gray1);
}

.logo {
  height: var(--header-height);
  background: var(--color-blue1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.logoLink {
  display: flex;
}

.header {
  display: flex;
  height: var(--header-height);
  border-bottom: 1px solid var(--color-gray3);
}

.footer {
  display: flex;
  align-items: center;
  height: var(--header-height);
  padding: 0 var(--length6);
  background-color: var(--color-gray4);
  color: var(--font-color3);
  font-weight: var(--fw-light-bold);
  text-transform: uppercase;
}

.section {
  display: flex;
  flex: 1 1 auto;
  min-width: 0;
  overflow: auto;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 1 auto;
  min-width: 0;
  padding: var(--length6);
  box-sizing: border-box;
  position: relative;
}

.breadcrumbs {
  flex: 1 1 auto;
}

@media screen and (min-width: $desktop) {
  .main {
    margin-left: $asideWidth;
  }

  .aside {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .header {
    padding: 0 var(--length6);
  }
}
:not(#RFS-StepperContainer) {
  button {
    text-transform: uppercase;
    letter-spacing: 0.02857em;
    > span {
      font-size: 14px !important;
    }
  }
}

